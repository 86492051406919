import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import tyface from '../assets/imgs/area2/tyface.png'
import arrow from '../assets/imgs/arrow.svg'

export const Loadscreen = ({ loading, setLoading, setIsSound }) => {
    return (
        <AnimatePresence>
            {loading &&
                <motion.div className='loadingscreen' key="modal"
                    initial={{ opacity: 1, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 1, y: "-100%", transition: { type: 'spring', duration: 4 } }}

                >
                    <motion.img animate={{
                        y: [-20, 20, -20], // Moves left, then right, then left again
                    }}
                        transition={{
                            repeat: Infinity, // Infinite loop
                            duration: 1, // Duration for each movement
                            ease: "linear" // Linear easing
                        }}
                        src={arrow} alt="" className="arrow arrow1" />
                    <motion.img

                        src={arrow} alt="" className="arrow arrow2" />
                    <motion.img src={arrow} alt="" className="arrow arrow3" />

                    <img src={tyface} alt="" className="tyface" />
                    <div className="enterbtn tf" onClick={() => { setLoading(false); setIsSound(true); }}>
                        ENTER
                    </div>
                </motion.div>
            }

        </AnimatePresence>

    )
}
