import { useState, useEffect } from "react";
// import { Aboutarea } from "./components/Aboutarea";
// import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
// import { Tokenomicsarea } from "./components/Tokenomicsarea";
import { Toparea, Footer } from "./components/Toparea";
import { Loadscreen } from "./components/Loadscreen";

function App() {


  const [loading, setLoading] = useState(true)

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 6000)
  // }, [])
  const [isSound, setIsSound] = useState(false)


  return (
    <div className="App">

      <Loadscreen loading={loading} setLoading={setLoading} setIsSound={setIsSound} />

      <Navbar isSound={isSound} setIsSound={setIsSound} />
      <Toparea />
      <Footer />
      {/* <Aboutarea />
      <Tokenomicsarea />
      <Footer /> */}


    </div>
  );
}

export default App;
