import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion'; // Import motion from Framer Motion
import navside from '../assets/imgs/navside.png'


import { FaXTwitter, FaDiscord, FaTelegram, } from "react-icons/fa6";
import dx from '../assets/imgs/logos/dexscreener.svg'
import dt from '../assets/imgs/logos/dextools.svg'

import audio from '../assets/audio.mp3'
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';

export const Navbar = ({ isSound, setIsSound }) => {



    const audioRef = useRef(null); // Reference to the audio element

    useEffect(() => {
        if (isSound) {
            // Play the audio if isSound is true
            audioRef.current.play();
        } else {
            // Pause the audio if isSound is false
            audioRef.current.pause();
        }
    }, [isSound]); // This effect depends on isSound, so it runs whenever isSound changes






    return (
        <div className='navbar blk'>
            <audio src={audio} ref={audioRef} loop muted={!isSound} allow="autoplay" />

            <img src={navside} alt="" className='navside s1' />
            <div className="nav-cnt ot">
                <div className="top">your favorite photographer on the blockchain</div>
                <div className="mid">$AWP on SOLANA :</div>
                <div className="icons">
                    <a href="https://twitter.com/ANSEMWIFPHOTO">
                        <FaXTwitter className="ico" />
                    </a>
                    <p className="sl">/</p>
                    <a href="https://t.me/ANSEMWIFPHOTO">
                        <FaTelegram className="ico" /></a>
                    <p className="sl">/</p>
                    <a href="https://dexscreener.com/solana/5NmLPVu4mJFJB9UdENE15xGx5KmRE4aMV64YiX78MDo1">
                        <img src={dx} alt="dx" className='dx' /></a>
                    <p className="sl">/</p>
                    <a href="https://www.dextools.io/app/en/solana/pair-explorer/5NmLPVu4mJFJB9UdENE15xGx5KmRE4aMV64YiX78MDo1">
                        <img src={dt} alt="dx" className='dx' /></a>
                    <p className="sl">/</p>

                    {
                        isSound ?
                            < FaVolumeUp className="ico" onClick={() => setIsSound(false)} /> :
                            <FaVolumeMute className="ico" onClick={() => setIsSound(true)} />
                    }
                </div>
            </div>
            <img src={navside} alt="" className='navside s2' />
        </div>
    )
}

