import React, { useEffect, useRef } from 'react';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';
import { FaXTwitter, FaTelegram, } from "react-icons/fa6";
import dx from '../assets/imgs/logos/dexscreener.svg'
import dx2 from '../assets/imgs/dx.svg'
import addy from '../assets/imgs/area2/addy.png'
import drakebox from '../assets/imgs/area2/cam.png'
import pillgrp from '../assets/imgs/area2/pillgrp.png'
import pillgrp2 from '../assets/imgs/area2/pillgrp2.png'
import crowd from '../assets/imgs/area2/crowd.png'

import toptxt from '../assets/imgs/area2/toptxt.png'
import circ from '../assets/imgs/area2/circ.png'
import circo from '../assets/imgs/circ.png'
import circ2 from '../assets/imgs/circ2.png'

import name from '../assets/imgs/area2/name.png'
import name2 from '../assets/imgs/area2/name2.png'

import buybtn from '../assets/imgs/area2/buy-btn.png'


import eye1 from '../assets/imgs/eye2.png'
import pupil1 from '../assets/imgs/area2/pupil1.png'
import bottle from '../assets/imgs/area2/bottle.gif'

import pupil2 from '../assets/imgs/pupil2.png'
import eyelid from '../assets/imgs/eyelid.png'


import tyface from '../assets/imgs/area2/tyface.png'
import rbottle from '../assets/imgs/area2/tyface.png'
import rbottle2 from '../assets/imgs/area2/ob2.png'
import whatabout from '../assets/imgs/whatabout.png'
import tybox from '../assets/imgs/ty-box1 1.png'
import cloud1 from '../assets/imgs/cloud1.png'
import cloud2 from '../assets/imgs/cloud2.png'
import ob from '../assets/imgs/area2/ob.png'
import ob2 from '../assets/imgs/area2/ob2.png'
import os from '../assets/imgs/area2/os.png'
import stats from '../assets/imgs/stats.png'
import star from '../assets/imgs/star.png'
import toronto from '../assets/imgs/area2/ship.png'
import cloudgrp from '../assets/imgs/area2/bottle.gif'

import cmc from '../assets/imgs/logos/CoinMarketCap.svg'
import cgk from '../assets/imgs/logos/CoinGecko.svg'

import itemcombo from '../assets/imgs/item-combo.png'

import face from '../assets/imgs/area2/face.png'
import longcircle from '../assets/imgs/longcircle.png'
import footernote from '../assets/imgs/footernote.png'



export const Toparea = () => {






    const pillAnimation = {
        float: {
            y: ["0%", "-10%", "0%"],
            rotate: [0, 10, -10, 0],
            transition: {
                y: {
                    yoyo: Infinity,
                    duration: 2,
                    ease: "easeInOut"
                },
                rotate: {
                    yoyo: Infinity,
                    duration: 2,
                    ease: "easeInOut"
                }
            }
        }
    };











    // EYES ANIM
    const mouseX = useMotionValue(0);
    const mouseY = useMotionValue(0);

    // Increase the range for more movement
    const range = 25; // Adjust this value as needed for the effect

    // Configure the spring for a lighter movement
    const springConfig = {
        stiffness: 50,  // Lower makes it more springy
        damping: 12,    // Higher makes the spring motion come to rest faster
        mass: 0.5,      // Lower mass will make the spring feel lighter
    };

    // Apply the spring effect to the motion values
    const pupilX = useSpring(useTransform(mouseX, [-1, 1], [-range, range]), springConfig);
    const pupilY = useSpring(useTransform(mouseY, [-1, 1], [-range, range]), springConfig);

    useEffect(() => {
        const handleMouseMove = (event) => {
            const eyeCnt = document.querySelector('.eye-cnt');
            if (eyeCnt) {
                const rect = eyeCnt.getBoundingClientRect();
                const mouseXPosition = (event.clientX - rect.left - rect.width / 2) / (rect.width / 4); // Increased sensitivity
                const mouseYPosition = (event.clientY - rect.top - rect.height / 2) / (rect.height / 4); // Increased sensitivity
                mouseX.set(mouseXPosition);
                mouseY.set(mouseYPosition);
            }
        };

        // Add mouse move event listener when component mounts
        window.addEventListener('mousemove', handleMouseMove);

        // Remove event listener when component unmounts
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, [mouseX, mouseY]);







    const cloudVariants = {
        drive: {


            y: ['0%', '360%', '0%'],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 4,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 4,
                    ease: "linear"
                }
            }
        }
    };





    const upTextVariants = {
        initial: {
            y: "10%",
        },
        animate: {
            y: '60%',
            transition: {
                duration: 50,
                ease: 'linear',
                repeat: Infinity,
                repeatType: 'loop',
            },
        },
    };



    const path1 = {
        drive: {
            x: [-800, 550, 650, -650, -750, -800],
            y: [-300, 200, -100, 500, -200, -300],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                }
            }
        }
    };


    const path2 = {
        drive: {
            x: [-900, 300, 700, -250, -450, -900],
            y: [-200, 100, -400, 400, -600, -200],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                }
            }
        }
    };



    const path3 = {
        drive: {
            x: [-200, 250, 1150, -350, -850, -200],
            y: [-100, -300, 200, -400, 500, -100],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                }
            }
        }
    };




    const path4 = {
        drive: {
            x: [-1000, 550, -300, 650, -750, -1000],
            y: [-700, 700, -50, 300, -200, -700],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                }
            }
        }
    };



    const path5 = {
        drive: {
            x: [-150, 200, 340, -150, 250, -150],
            y: [-150, -200, 100, -150, 200, -150],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                }
            }
        }
    };


    const path6 = {
        drive: {
            x: [600, 450, -800, 800, -750, 600],
            y: [700, 100, -350, 250, -50, 700],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                }
            }
        }
    };



    const path7 = {
        drive: {
            x: [600, 450, -800, 800, -750, 600],
            y: [700, 100, -350, 250, -50, 700],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                }
            }
        }
    };



    const path8 = {
        drive: {
            x: [600, -150, -800, 800, -650, 600],
            y: [900, 100, -0, 50, -50, 900],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 8,
                    ease: "linear"
                }
            }
        }
    };



    const wobble = {
        rotate: [0, -15, 15, 0], // Degrees to rotate to, back and forth
    };


    return (
        <>

            <div className='toparea'>


                <img src={crowd} alt="" className='crowd' />

                <motion.div
                    animate={path1.drive}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <motion.img
                        animate={wobble}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop" // Ensure the animation loops from start to end
                        }}
                        src={tyface}
                        className='blob1 blob'
                        alt="Your Image"
                    />
                </motion.div>



                <motion.div
                    animate={path2.drive}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <motion.img
                        animate={wobble}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop" // Ensure the animation loops from start to end
                        }}
                        src={tyface}
                        className='blob2 blob'
                        alt="Your Image"
                    />
                </motion.div>



                <motion.div
                    animate={path3.drive}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <motion.img
                        animate={wobble}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop" // Ensure the animation loops from start to end
                        }}
                        src={tyface}
                        className='blob3 blob'
                        alt="Your Image"
                    />
                </motion.div>

                <motion.div
                    animate={path4.drive}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <motion.img
                        animate={wobble}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop" // Ensure the animation loops from start to end
                        }}
                        src={tyface}
                        className='blob4 blob'
                        alt="Your Image"
                    />
                </motion.div>


                <motion.div
                    animate={path5.drive}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <motion.img
                        animate={wobble}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop" // Ensure the animation loops from start to end
                        }}
                        src={ob}
                        className='blob5 blob'
                        alt="Your Image"
                    />
                </motion.div>



                <motion.div
                    animate={path6.drive}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <motion.img
                        animate={wobble}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop" // Ensure the animation loops from start to end
                        }}
                        src={ob}
                        className='blob6 blob'
                        alt="Your Image"
                    />
                </motion.div>


                <motion.div
                    animate={path7.drive}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <motion.img
                        animate={wobble}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop" // Ensure the animation loops from start to end
                        }}
                        src={ob2}
                        className='blob7 blob'
                        alt="Your Image"
                    />
                </motion.div>


                <motion.div
                    animate={path8.drive}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <motion.img
                        animate={wobble}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop" // Ensure the animation loops from start to end
                        }}
                        src={ob2}
                        className='blob8 blob'
                        alt="Your Image"
                    />
                </motion.div>






                <div className="left">
                    <motion.img animate={{
                        rotate: [0, -2.5, 2.5, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} src={drakebox} alt="drakebox" className='drakebox' />
                    <motion.div className="pillcnt" variants={upTextVariants} initial="initial" animate="animate">
                        {Array.from({ length: 30 }).map((_, index) => (
                            <React.Fragment key={index} >
                                <img src={pillgrp} alt="pillgrp" className='pillgrp' />
                            </React.Fragment>
                        ))}


                    </motion.div>
                </div>


                <img src={toptxt} className="center"></img>

                <div className="bottomcenter">
                    <a href="https://www.dextools.io/app/en/solana/pair-explorer/5NmLPVu4mJFJB9UdENE15xGx5KmRE4aMV64YiX78MDo1"><img src={addy} alt="" className='addy' /></a>
                    <a href="mailto:AWPmeme@protonmail.me"><div className="contactbtn tf white">AWPmeme@protonmail.me</div>
                    </a>

                </div>
                <div className="right">
                    <div className="circ-txt">
                        <motion.img src={circ} alt="circ" className='circ' animate={{ rotate: 360 }} // Full circle rotation
                            transition={{
                                duration: 2.5, // Adjust for quicker or slower spin
                                ease: "linear", // Linear easing for constant speed
                                repeat: Infinity, // Repeat the animation infinitely
                                repeatType: "loop", // Ensure the animation loops from start to end
                            }} />
                        <img src={name} alt="name" className='name' />
                    </div>
                    {/* <a href="https://raydium.io/swap/"><img src={buybtn} alt="buybtn" className='buybtn' />
                    </a> */}
                    <a className="buybtn" href="https://jup.ag/swap/SOL-HAvWYZjA4eLA56gZs2iUZub8E12Pk3VYgmrW3Lmw25D"><span>BUY $AWP</span><em></em></a>
                </div>
            </div>



            <div className="midarea">
                <img src={bottle} alt="" className="bottle1" />
                <a href="https://coinmarketcap.com/currencies/ansem-wif-photographer/">
                    <img src={cmc} alt="" className="cmc" /></a>
                <a href="https://www.coingecko.com/en/coins/ansem-wif-photographer">
                    <img src={cgk} alt="" className="cgk" /></a>

                <div className="pillshower">
                    <motion.img animate={{
                        rotate: [0, 2.5, -2.5, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 2, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} src={pillgrp2} alt="pillgrp2" className='pillgrp2' />
                    <motion.img animate={{
                        rotate: [0, -2.5, 2.5, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 2, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} src={pillgrp2} alt="pillgrp2" className='pillgrp2' />
                    <motion.img animate={{
                        rotate: [0, 2.5, -2.5, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 2, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} src={pillgrp2} alt="pillgrp2" className='pillgrp2' />
                    <motion.img animate={{
                        rotate: [0, 2.5, -2.5, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 2, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} src={pillgrp2} alt="pillgrp2" className='pillgrp2' />
                    <motion.img animate={{
                        rotate: [0, 2.5, -2.5, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 0.5, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} src={pillgrp2} alt="pillgrp2" className='pillgrp2' />

                </div>

                <div className="eye-cnt">
                    <img src={eye1} alt="eye1" className='eye1' />
                    <motion.img
                        src={pupil1}
                        alt="pupil1"
                        className='pupil1'
                        style={{
                            x: pupilX,
                            y: pupilY,
                            position: 'absolute',
                            translateX: '-41%',
                            translateY: '-52.5%'

                        }}
                    />
                </div>


            </div>


            <div className="lowerarea">

                <div className="leftest">

                    <img src={tyface} alt="tyface" className='tyface' />

                    <div className="bottlearea">
                        <motion.img animate={{
                            rotate: [0, 10, -10, 0], // Degrees to rotate to, back and forth
                        }}
                            transition={{
                                duration: 0.5, // Duration of one cycle of rotation
                                ease: "linear", // Linear easing for constant speed
                                repeat: Infinity, // Repeat the animation infinitely
                                repeatType: "loop", // Ensure the animation loops from start to end
                            }} src={rbottle} alt="rbottle" className='bootle' />
                        <motion.img animate={{
                            rotate: [0, 10, -10, 0], // Degrees to rotate to, back and forth
                        }}
                            transition={{
                                duration: 0.5, // Duration of one cycle of rotation
                                ease: "linear", // Linear easing for constant speed
                                repeat: Infinity, // Repeat the animation infinitely
                                repeatType: "loop", // Ensure the animation loops from start to end
                            }} src={rbottle2} alt="rbottle" className='bootle' />
                        <motion.img animate={{
                            rotate: [0, 10, -10, 0], // Degrees to rotate to, back and forth
                        }}
                            transition={{
                                duration: 0.5, // Duration of one cycle of rotation
                                ease: "linear", // Linear easing for constant speed
                                repeat: Infinity, // Repeat the animation infinitely
                                repeatType: "loop", // Ensure the animation loops from start to end
                            }} src={rbottle} alt="rbottle" className='bootle' />

                    </div>
                </div>

                <div className="center">

                    <div className="pinkarea area">

                        <div className="starcnt">
                            <motion.img src={star} alt="star" className='star' animate={{ rotate: 360 }} // Full circle rotation
                                transition={{
                                    duration: 1, // Adjust for quicker or slower spin
                                    ease: "linear", // Linear easing for constant speed
                                    repeat: Infinity, // Repeat the animation infinitely
                                    repeatType: "loop", // Ensure the animation loops from start to end
                                }}
                            />
                            <div className="hp blue ot uptx">
                                <span className="pink">HP</span>: <br></br> 999/10
                            </div>
                        </div>

                        <img src={name} alt="pill" className='pill' />

                        <img src={stats} alt="stats" className='stats' />
                        <div className="circ-txt">
                            <motion.img src={circo} alt="circ" className='circ'
                                animate={{ rotate: -360 }} // Full circle rotation
                                transition={{
                                    duration: 4, // Adjust for quicker or slower spin
                                    ease: "linear", // Linear easing for constant speed
                                    repeat: Infinity, // Repeat the animation infinitely
                                    repeatType: "loop", // Ensure the animation loops from start to end
                                }}
                            />
                            <div className="txt ot pink">
                                SUPPLY : <span className="blue">100M</span><br></br>
                                TAXES : <span className="blue">0/0</span></div>
                        </div>
                        <motion.img src={os} alt="os" className='os' animate={{
                            y: ["0%", "40%", "0%"], // Move left and right
                            x: ["-50%"]
                        }}
                            transition={{
                                duration: 2, // Duration of each cycle
                                ease: "easeInOut", // Smooth easing in and out
                                repeat: Infinity, // Loop infinitely
                                repeatType: "loop", // Ensure it loops in a standard fashion
                                repeatDelay: 1 // Delay before repeating the animation
                            }} />

                    </div>
                    <img src={whatabout} alt="whatabout" className='whatabout' />
                    <img src={tybox} alt="tybox" className='tybox' />

                    <div className="bluearea area">
                        <img src={cloud1} alt="cloud1" className='cloud cloud1' />
                        <img src={cloud2} alt="cloud2" className='cloud cloud2' />
                        <img src={toronto} alt="toronto" className='toronto' />

                        <motion.img
                            src={ob}
                            alt="ob"
                            className='ob'
                            animate={{
                                y: ["0%", "40%", "0%"], // Move left and right
                                x: ["-50%"]
                            }}
                            transition={{
                                duration: 2, // Duration of each cycle
                                ease: "easeInOut", // Smooth easing in and out
                                repeat: Infinity, // Loop infinitely
                                repeatType: "loop", // Ensure it loops in a standard fashion
                                repeatDelay: 0.5 // Delay before repeating the animation
                            }}
                        />


                    </div>
                </div>
                <div className="yellowarea tf">$AWP ~ THE BEST ASIAN PHOTOGRAPHER IN THE WORLD</div>

                <div className="righest">

                    <motion.img src={cloudgrp} alt="cloudgrp" className='cloudgrp' variants={cloudVariants} animate='drive' style={{
                        position: 'absolute',
                        top: 'initial Y position',
                        left: 'initial X position',
                    }} />
                </div>
            </div>



        </>

    )
}


export const Footer = () => {

    // EYES ANIM
    const mouseX = useMotionValue(0);
    const mouseY = useMotionValue(0);

    // Increase the range for more movement
    const range = 8; // Adjust this value as needed for the effect

    // Configure the spring for a lighter movement
    const springConfig = {
        stiffness: 50,  // Lower makes it more springy
        damping: 12,    // Higher makes the spring motion come to rest faster
        mass: 0.5,      // Lower mass will make the spring feel lighter
    };

    // Apply the spring effect to the motion values
    const pupilX = useSpring(useTransform(mouseX, [-1, 1], [-range, range]), springConfig);
    const pupilY = useSpring(useTransform(mouseY, [-1, 1], [-range, range]), springConfig);

    useEffect(() => {
        const handleMouseMove = (event) => {
            const eyeCnt = document.querySelector('.eye-cnt2');
            if (eyeCnt) {
                const rect = eyeCnt.getBoundingClientRect();
                const mouseXPosition = (event.clientX - rect.left - rect.width / 2) / (rect.width / 4); // Increased sensitivity
                const mouseYPosition = (event.clientY - rect.top - rect.height / 2) / (rect.height / 4); // Increased sensitivity
                mouseX.set(mouseXPosition);
                mouseY.set(mouseYPosition);
            }
        };

        // Add mouse move event listener when component mounts
        window.addEventListener('mousemove', handleMouseMove);

        // Remove event listener when component unmounts
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, [mouseX, mouseY]);



    return (
        <div className="footerarea">
            <div className="left">

                <div className="circ-txt">
                    <motion.img src={circ2} alt="circ" className='circ' animate={{ rotate: 360 }} // Full circle rotation
                        transition={{
                            duration: 8, // Adjust for quicker or slower spin
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }}
                    />
                    <div className="txt ot blue">
                        do <span className="red">it</span> for<br></br> him <span className="pink">the best</span> buddy <br></br> <span className="yellow">that could ever</span> snap<br></br> <span className="pink">you”</span> ~ ansem
                    </div>
                </div>

                <div className="face-cnt">
                    <div className="eye-cnt2">
                        <img src={eye1} alt="eye1" className='eye1' />
                        <motion.img
                            src={pupil2}
                            alt="pupil1"
                            className='pupil1'
                            style={{
                                x: pupilX,
                                y: pupilY,
                                position: 'absolute',
                                translateX: '-50%',
                                translateY: '-50%'

                            }}
                        />
                    </div>

                    <div className="eye-cnt3">
                        <img src={eyelid} alt='eyelid' className='eyelid' />
                        <img src={eye1} alt="eye1" className='eye1' />
                        <motion.img
                            src={pupil2}
                            alt="pupil1"
                            className='pupil1'
                            style={{
                                x: pupilX,
                                y: pupilY,
                                position: 'absolute',
                                translateX: '-50%',
                                translateY: '-50%'

                            }}
                        />
                    </div>
                </div>

                <div className="warntxt pink tf">
                    this is a memecOin <span className="blue">purely</span> fOr entertainment purpOses
                </div>

                <img src={face} alt="face" className='face' />
                <motion.img animate={{
                    rotate: [0, 2.5, -2.5, 0], // Degrees to rotate to, back and forth
                }}
                    transition={{
                        duration: 0.5, // Duration of one cycle of rotation
                        ease: "linear", // Linear easing for constant speed
                        repeat: Infinity, // Repeat the animation infinitely
                        repeatType: "loop", // Ensure the animation loops from start to end
                    }} src={itemcombo} alt="itemcombo" className='itemcombo' />

            </div>

            <div className="right">
                <img src={name2} alt="name" className='name' />
                <div className="animarea">
                    <img src={longcircle} alt="longcircle" className='longcircle' />
                    <div className="icons">
                        <a href="https://twitter.com/ANSEMWIFPHOTO">
                            <FaXTwitter className="ico" /></a>
                        <a href="https://t.me/ANSEMWIFPHOTO">
                            <FaTelegram className="ico" /></a>
                        <a href="https://dexscreener.com/solana/5NmLPVu4mJFJB9UdENE15xGx5KmRE4aMV64YiX78MDo1"><img src={dx2} alt="dx" className='ico' /></a>

                    </div>
                </div>
                <img src={footernote} alt="footernote" className='footernote' />
            </div >
        </div >)
}
